.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.root span {
  display: block;
}

.root a {
  margin: auto;
  margin-top: 2em;
  margin-bottom: 4em;
  font-size: 0.75em;
  color: #4D4D4D;
  text-decoration: none;
}
