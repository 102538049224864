.login {}

.login label {
  width: 7em;
}

.login .forgot {
  margin: auto;
  margin-top: 2em;
  margin-bottom: 4em;
  font-size: 0.75em;
  color: #4D4D4D;
  text-decoration: none;
}
